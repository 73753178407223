<template>
  <div v-if="structure_loaded" class="report-viewer-container">
    <!-- Tabs -->
    <v-tabs
      class="spaces-tabs"
      color="var(--hb-primary-dark)"
      :value="activeTabValue"
      v-if="$route.name && $route.name.toLowerCase() === `spaces` && report_type!= `space_group_spaces` && report_type!=`move_in`"
    >
      <v-tab class="text-capitalize" :ripple="false" @click="tabChange('list')">
        List
      </v-tab>
      <v-tab class="text-capitalize" :ripple="false" @click="tabChange('map')">
        Map
      </v-tab>
      <v-tab class="text-capitalize" :ripple="false" @click="tabChange('space_groups')">
        Space Groups
      </v-tab>
    </v-tabs>
    <v-divider
      class="space-tabs-bottom-divider"
      v-if="show_divider && !show_title && $route.name && $route.name.toLowerCase() === `spaces`"
    ></v-divider>

    <hb-breadcrumb class="non-cursor" v-if="show_title && !hide_back_link">
      <span @click="back()" style="cursor:pointer;"> Back to All Reports </span>
    </hb-breadcrumb>

    <!-- Header Bar for List Tab -->
    <div v-if="tab === 'list' && !left_slot" v-show="!isMultipleEdit">
      <hb-header
        :divider="false"
        v-if="
          show_views ||
            show_property_selector ||
            show_search ||
            actions_panel.length
        "
        full
        :padding="false"
      >
      <v-row no-gutters class="align-center" :class="{'justify-between' : nationalAccountMobileCondition || nationalAccountListMobileCondition}">
          <div v-if="!searchLeftAligned" class="d-flex align-top my-3" :class="{'pr-4':!nationalAccountMobileCondition, 'national-account-select-property-wrapper': nationalAccountListMobileCondition, 'national-account-wrapper-width' : nationalAccountMobileCondition}">
            <slot name="globalFilter"></slot>
            <span :class="{'inline-block':nationalAccountMobileCondition}" v-if="show_views">
              <hb-autocomplete
                rounded
                :items="views"
                item-text="name"
                item-value="id"
                placeholder="Select"
                :clearable="false"
                :value="report"
                return-object
                @change="changeView"
                :width="comboBoxWidth"
                style="display:inline-block"
              >
              </hb-autocomplete>
            </span>

            <span v-if="show_title">
              <hb-page-header
                :title="report_name"
                :description="description"
              ></hb-page-header>
            </span>
            <span v-if="show_property_selector">
              <v-autocomplete
                v-if="report && report.filters"
                multiple
                dense
                hide-details
                rounded
                :items="properties"
                item-text="name"
                item-value="id"
                label="Select A Property"
                v-model="report.filters.search.currentProperty"
                background-color="white"
                singleLine
                outlined
                prepend-inner-icon="mdi-magnify"
                placeholder="Select A Property"
                @change="setSearchParams(null)"
                height="48"
                :class="{ 'hb-header-select': $vuetify.breakpoint.mdAndUp }"
                class="hb-header-autocomplete"
              >
                <template v-slot:append>
                  <v-row class="pl-2 d-flex align-center">
                    <span
                      class="mt-0 pt-1 mr-2 pl-2 hb-larger-font-size hb-text-lighter"
                      >view</span
                    >
                    <svg
                      class="mr-3 mt-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="7.5"
                        stroke="#E0E3E8"
                      />
                      <path
                        d="M8.17709 10.623C7.73596 10.623 7.51097 11.1527 7.81725 11.4701L11.6402 15.4329C11.8368 15.6367 12.1632 15.6367 12.3598 15.4329L16.1828 11.4701C16.489 11.1527 16.264 10.623 15.8229 10.623H8.17709Z"
                        fill="#677380"
                      />
                    </svg>
                  </v-row>
                </template>
              </v-autocomplete>
            </span>
            <span v-if="conditions && conditions.show_count_tenant" class="title">
              <span v-if="conditions.select_all">{{
                num_results - un_checked.length
              }}</span
              ><span v-else>{{ selected.length }}</span> Tenants
            </span>
            <span v-show="!isClean && !conditions" class="mt-2">
              <hb-link text color="primary" class="mx-3" @click="resetReport"
                >Reset</hb-link
              >
            </span>
          </div>
          <v-spacer v-if="!searchLeftAligned"></v-spacer>
          <hb-btn v-if="nationalAccountListMobileCondition && report_type === 'active_national_accounts'" :class="{'ml-2': !nationalAccountListMobileCondition}" color="secondary" prepend-icon="mdi-plus" @click="addNationalAccount()">Add National Account</hb-btn>
          <div class="my-3 align-center" :class="{'national-account-wrapper-width' : nationalAccountMobileCondition}">
            <hb-text-field
              v-if="report && report.filters && show_search"
              search
              @keyup.enter.prevent="setSearchParams({ search: searchField })"
              v-model="searchField"
              :placeholder="
                report_type === 'active_tenants'
                  ? 'Search Tenants'
                  : report_type === 'spaces'
                  ? 'Search Spaces'
                  : report_type === 'active_leads'
                  ? 'Search Leads'
                  : report_type === 'active_national_accounts'
                  ? 'Search National Accounts'
                  : report_type === 'national_account_unit_list'
                  ? 'Search'
                  : report_type === 'promotion_library'
                  ? 'Search for Promotions or Discounts'
                  : 'Search This Report'
              "
              :width="searchBarWidth"
            ></hb-text-field>
            <span v-show="searchLeftAligned && !isClean && !conditions" class="mt-2">
              <hb-link text color="primary" class="mx-3" @click="resetReport"
                >Reset</hb-link
              >
            </span>
          </div>
          <hb-btn :class="{'ml-2': !nationalAccountListMobileCondition}" v-if="report_type === 'active_national_accounts' && !nationalAccountListMobileCondition" color="secondary" prepend-icon="mdi-plus" @click="addNationalAccount()">Add National Account</hb-btn>
          <div class="my-3 align-center" :class="{ 'ml-auto' : $vuetify.breakpoint.mdAndDown }">

            <!--// BCT: Added action icon 'Add Spaces' and rearrange the sequence 1.Save Report, 2.Export Report, 3.Set Columns, 4.Advanced, 5.Add Spaces, 6.Bulk Actions, 7.Filters  -->
            <div class="d-flex ml-2">
              <hb-btn
                class="ml-1"
                v-if="showAction('report_options')"
                icon
                tooltip="Data Viewer Options"
                :active="active_action === 'report_options'"
                @click="toggleActionsWindow('report_options')"
                >mdi-poll-box-outline</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('upload') || showAction('download')"
                icon
                tooltip="Download / Upload"
                :active="active_action === 'download'"
                @click="toggleActionsWindow('download')"
                >mdi-swap-vertical-bold</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('save') && hasPermission('create_custom_reports')"
                icon
                tooltip="Save Report"
                :active="active_action === 'save'"
                @click="toggleActionsWindow('save')"
                >mdi-content-save</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('export') && hasPermission('download_reports')"
                icon
                tooltip="Export Report"
                :active="active_action === 'export'"
                @click="toggleActionsWindow('export')"
                >mdi-download</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('columns')"
                icon
                tooltip="Set Columns"
                :active="active_action === 'columns'"
                @click="toggleActionsWindow('columns')"
                >mdi-table-actions-custom-2</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('advanced')"
                icon
                tooltip="Advanced"
                :active="active_action === 'advanced'"
                @click="toggleActionsWindow('advanced')"
                >mdi-table-actions-custom-3</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('add_spaces')"
                icon
                tooltip="Add Spaces"
                :active="isAddSpacesActive"
                @click="addSpaces('add_spaces')"
                >mdi-custom-add-spaces</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('bulk_edit') && disableBulkEditForDynamicReport"
                icon
                tooltip="Bulk Edit"
                :active="isBulkEditActive || active_action === 'bulk_edit'"
                @click="bulkEditSpaces('bulk_edit')"
                >mdi-square-edit-outline</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-show="hasFilters"
                v-if="showAction('filters')"
                icon
                tooltip="Filters"
                :active="active_action === 'filters'"
                @click="toggleActionsWindow('filters')"
                >mdi-filter-variant</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('master_filter')"
                icon
                tooltip="Filters"
                :active="showMasterFilterModal"
                @click="showMasterFilter"
                >mdi-filter-variant</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="actionButtonLabel"
                color="secondary"
                tooltip="Add new"
                @click="$emit('action-btn-click')"
                >{{actionButtonLabel}}</hb-btn
              >
            </div>
            <!-- BCT: Actions icons for Spaces Tab //-->
          </div>
        </v-row>
      </hb-header>
    </div>

    <div v-if="tab === 'list' && left_slot">
      <hb-header
        :divider="false"
      >
        <template v-slot:left>
          <slot name="left" />
        </template>
        <template v-slot:right>
          <slot name="right" />

          <span v-show="!isClean && !conditions" class="mt-2">
            <hb-link 
              text 
              color="primary" 
              class="mx-3" 
              @click="resetReport"
            >
              Reset
            </hb-link>
          </span>
          <div v-if="show_views || show_search || actions_panel.length" class="mb-n1 align-center">
            <hb-text-field
              v-if="report && report.filters && show_search"
              search
              @keyup.enter.prevent="setSearchParams({ search: searchField })"
              v-model="searchField"
              :placeholder="
                report_type === 'active_tenants'
                  ? 'Search Tenants'
                  : report_type === 'spaces'
                  ? 'Search Spaces'
                  : report_type === 'active_leads'
                  ? 'Search Leads'
                  : 'Search This Report'
              "
              width="370px"
            />
          </div>
          <div v-if="show_views || show_search || actions_panel.length" class="mb-n1 align-center" :class="{ 'ml-auto' : $vuetify.breakpoint.mdAndDown }">
            <!--// BCT: Added action icon 'Add Spaces' and rearrange the sequence 1.Save Report, 2.Export Report, 3.Set Columns, 4.Advanced, 5.Add Spaces, 6.Bulk Actions, 7.Filters  -->
            <div class="d-flex ml-2">
              <hb-btn
                class="ml-1"
                v-if="showAction('report_options')"
                icon
                tooltip="Data Viewer Options"
                :active="active_action === 'report_options'"
                @click="toggleActionsWindow('report_options')"
                >mdi-poll-box-outline</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('upload') || showAction('download')"
                icon
                tooltip="Download / Upload"
                :active="active_action === 'download'"
                @click="toggleActionsWindow('download')"
                >mdi-swap-vertical-bold</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('save')"
                icon
                tooltip="Save Report"
                :active="active_action === 'save'"
                @click="toggleActionsWindow('save')"
                >mdi-content-save</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('export')"
                icon
                tooltip="Export Report"
                :active="active_action === 'export'"
                @click="toggleActionsWindow('export')"
                >mdi-download</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('columns')"
                icon
                tooltip="Set Columns"
                :active="active_action === 'columns'"
                @click="toggleActionsWindow('columns')"
                >mdi-table-actions-custom-2</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('advanced')"
                icon
                tooltip="Advanced"
                :active="active_action === 'advanced'"
                @click="toggleActionsWindow('advanced')"
                >mdi-table-actions-custom-3</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('add_spaces')"
                icon
                tooltip="Add Spaces"
                :active="isAddSpacesActive"
                @click="addSpaces('add_spaces')"
                >mdi-custom-add-spaces</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('bulk_edit') && !isMultipleEdit"
                icon
                tooltip="Bulk Edit"
                :active="isBulkEditActive || active_action === 'bulk_edit'"
                @click="bulkEditSpaces('bulk_edit')"
                >mdi-square-edit-outline</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-show="hasFilters"
                v-if="showAction('filters')"
                icon
                tooltip="Filters"
                :active="active_action === 'filters'"
                @click="toggleActionsWindow('filters')"
                >mdi-filter-variant</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="showAction('master_filter')"
                icon
                tooltip="Filters"
                :active="showMasterFilterModal"
                @click="showMasterFilter"
                >mdi-filter-variant</hb-btn
              >
              <hb-btn
                class="ml-1"
                v-if="actionButtonLabel"
                color="secondary"
                prepend-icon="mdi-plus"
                tooltip="Add new"
                @click="$emit('action-btn-click')"
                >{{actionButtonLabel}}</hb-btn
              >
            </div>
            <!-- BCT: Actions icons for Spaces Tab //-->
          </div>
        </template>
      </hb-header>
    </div>

    <div v-if="tab === 'space_groups' && left_slot">
      <hb-header
      class="pb-0"
        :divider="false"
      >
       <!-- property selector -->
        <HbPropertySelector 
            @change="fetchSpaceGroups"
            id="facility" 
            :items="selectedProperties" 
            v-model="searchRateReportForProperty"
            item-value="id" 
            item-text="name" 
            height="40"
            :class="{ 'hb-header-select': $vuetify.breakpoint.mdAndUp }">
        </HbPropertySelector>

        <v-row no-gutters class="align-center">
          <div class="d-flex align-top my-3 pr-4">
            <span>
              <hb-autocomplete
                rounded
                :items="spaceGroups"
                item-text="name"
                item-value="id"
                placeholder="Select Grouping Profile"
                :clearable="false"
                return-object
                @change="searchRateReportEvent"
                width="370px"
                style="display:inline-block"
              >
              </hb-autocomplete>
            </span>

            <span v-if="show_title">
              <hb-page-header
                :title="report_name"
                :description="description"
              ></hb-page-header>
            </span>
            
            
            <span v-show="!isClean && !conditions" class="mt-2">
              <hb-link text color="primary" class="mx-3" @click="resetReport"
                >Reset</hb-link
              >
            </span>
          </div>
          
        </v-row>
      </hb-header>
    </div>
    <!-- Header Bar for Map Tab -->
    <div v-if="tab === 'map'" style="margin-bottom: -2px">
      <hb-header
        class="spaces-map-header-bar"
        :divider="false"
        v-if="
          show_views ||
          show_property_selector ||
          show_search ||
          actions_panel.length
        "
      >
        <template v-slot:left>
          <slot name="left">
            <span v-if="show_views">
              <HbAutocomplete
                rounded
                :items="mapProperties"
                item-text="name"
                item-value="id"
                :value="report"
                return-object
                @change="changeProperty"
                v-if="show_views"
                placeholder="Select Property"
                width="370px"
              />
            </span>
            <span class="floor-select pl-3" v-if="floors.length">
              <hb-autocomplete
                rounded
                :items="floors"
                v-model="currentFloor"
                maxLength="0"
                @change="changeFloor"
                placeholder="Select Floor"
                width="370px"
              >
                <template v-slot:selection="data">
                  <span class="hb-text-night truncate"
                    >Floor - {{ data.item }}</span
                  >
                </template>

                <template v-slot:item="data">
                  <span class="hb-text-night truncate"
                    >Floor - {{ data.item }}</span
                  >
                </template>
              </hb-autocomplete>
            </span>

            <span v-if="show_title">
              <hb-page-header
                :title="report_name"
                :description="description"
              ></hb-page-header>
            </span>
            <span v-if="show_property_selector">
              <v-autocomplete
                v-if="report && report.filters"
                multiple
                dense
                hide-details
                rounded
                :items="properties"
                item-text="name"
                item-value="id"
                label="Select A Property"
                v-model="report.filters.search.currentProperty"
                background-color="white"
                singleLine
                outlined
                prepend-inner-icon="mdi-magnify"
                placeholder="Select A Property"
                @change="setSearchParams(null)"
                height="48"
                :class="{ 'hb-header-select': $vuetify.breakpoint.mdAndUp }"
                class="hb-header-autocomplete"
              >
                <template v-slot:append>
                  <v-row class="pl-2 d-flex align-center">
                    <span
                      class="
                        mt-0
                        pt-1
                        mr-2
                        pl-2
                        hb-larger-font-size hb-text-lighter
                      "
                      >view</span
                    >
                    <svg
                      class="mr-3 mt-1"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="7.5"
                        stroke="#E0E3E8"
                      />
                      <path
                        d="M8.17709 10.623C7.73596 10.623 7.51097 11.1527 7.81725 11.4701L11.6402 15.4329C11.8368 15.6367 12.1632 15.6367 12.3598 15.4329L16.1828 11.4701C16.489 11.1527 16.264 10.623 15.8229 10.623H8.17709Z"
                        fill="#677380"
                      />
                    </svg>
                  </v-row>
                </template>
              </v-autocomplete>
            </span>
            <span
              v-if="conditions && conditions.show_count_tenant"
              class="title"
            >
              <span v-if="conditions.select_all">{{
                num_results - un_checked.length
              }}</span
              ><span v-else>{{ selected.length }}</span> Tenants
            </span>
            <span v-show="!isClean && !conditions">
              <hb-link text color="primary" class="ml-3" @click="resetReport"
                >Reset</hb-link
              >
            </span>
          </slot>
        </template>

        <template v-slot:right>
          <v-text-field
            v-if="false"
            outlined
            hide-details
            dense
            @keyup.enter.prevent="setSearchParams({ search: searchField })"
            v-model="searchField"
            prepend-inner-icon="mdi-magnify"
            clearable
            clear-icon="mdi-close-circle"
            class="hb-text-field-custom d-flex"
            :placeholder="
              report_type === 'active_tenants'
                ? 'Search Tenants'
                : report_type === 'spaces'
                ? 'Search Spaces'
                : report_type === 'active_leads'
                ? 'Search Leads'
                : 'Search This Report'
            "
          ></v-text-field>

          <!--// BCT: Added action icon 'Add Spaces' and rearrange the sequence 1.Save Report, 2.Export Report, 3.Set Columns, 4.Advanced, 5.Add Spaces, 6.Bulk Actions, 7.Filters  -->
          <div class="d-flex ml-2">
            <hb-btn
              class="ml-1"
              v-if="showAction('export') && mapExportAvailable"
              icon
              tooltip="Export Report"
              :active="active_action === 'export'"
              @click="toggleActionsWindow('export')"
              >mdi-download</hb-btn
            >
            <hb-btn
              class="ml-1"
              v-show="hasFilters"
              v-if="showAction('filters')"
              icon
              tooltip="Filters"
              :active="active_action === 'filters'"
              @click="toggleActionsWindow('filters')"
              >mdi-filter-variant</hb-btn
            >
            <hb-btn
              class="ml-1"
              v-if="showAction('master_filter')"
              icon
              tooltip="Filters"
              :active="showMasterFilterModal"
              @click="showMasterFilter"
              >mdi-filter-variant</hb-btn
            >
            <hb-btn
              class="ml-1"
              v-if="actionButtonLabel"
              color="secondary"
              prepend-icon="mdi-plus"
              tooltip="Add new"
              @click="$emit('action-btn-click')"
              >{{actionButtonLabel}}</hb-btn
            >
          </div>
          <!-- BCT: Actions icons for Spaces Tab //-->
        </template>
      </hb-header>
    </div>

    <status
      @resetStatus="errorClear($options.name)"
      v-if="errorHas($options.name)"
      :message="errorGet($options.name)"
      status="error"
    ></status>

    <v-row dense style="flex: 0" v-if="show_result_count">
      <v-col class="text-right">{{ num_results }} results</v-col>
    </v-row>

    <!-- Content Area for List Tab -->
    <v-row
      no-gutters
      class="grid-container"
      style="flex: 1; position: relative"
      v-if="tab === 'list'"
    >
      <v-col>
        <div v-show="checkboxes_disabled" class="mask"></div>

        <!--  BCT: Added statusBar -->

        <ReportViewerV2 ref="HBReportViewer" :options="consolidatedOptions" />
      </v-col>

      <v-col
        class="hb-elevation-bulk-edit-blade"
        style="
          flex: 0 1 600px;
          height: 100%;
          position: relative;
          overflow: hidden;
          z-index: 5;
        "
        v-show="active_action && !isMultipleEdit"
      >
        <div class="filter-container" v-if="active_action === 'columns'">
          <actions-panel-header @close="toggleActionsWindow('columns')">
            <div style="position: relative; top: 2px">
              <hb-icon
                small
                color="#101318"
                class="mr-2"
                mdi-code="mdi-table-actions-custom-2"
              >
              </hb-icon>
            </div>
            <div>Set Columns</div>
          </actions-panel-header>
          <columns
            v-if="report.filters.columns && structure.column_structure"
            :structure="structure.column_structure"
            :columns="report.filters.columns"
            :groups="report.filters.groups"
            @setColumns="setColumns"
            @close="toggleActionsWindow('columns')"
          >
          </columns>
        </div>

        <div class="filter-container" v-if="active_action === 'download'">
          <actions-panel-header @close="toggleActionsWindow('download')">
            <div class="mt-n1">
              <hb-icon 
                :small="true" 
                color="#101318" 
                class="mr-2"
                mdi-icon="mdi-swap-vertical-bold"
              ></hb-icon
              >
            </div>
            <div>
              {{ actions_panel.includes('download')
                  ? actions_panel.includes('upload') ? 'Download / Upload' : 'Download'
                  : 'Upload'
              }}
            </div>
          </actions-panel-header>

          <download-upload
            v-if="report.filters"
            :actions_panel="actions_panel"
            :template="report.template"
            :selected="report"
            @close="toggleActionsWindow('download')"
          ></download-upload>
        </div>

        <div class="filter-container" v-if="active_action === 'save'">
          <actions-panel-header @close="toggleActionsWindow('save')">
            <div class="mt-n1">
              <hb-icon
                small
                color="#101318"
                class="mr-2"
                mdi-code="mdi-content-save"
              >
              </hb-icon>
            </div>
            <div>Save Report</div>
          </actions-panel-header>

          <save-report
            v-if="report.filters"
            :config="report.filters"
            :template="report.template"
            :selected="report.id"
            :contact_id="contact?.id"
            @close="toggleActionsWindow('save')"
            @saved="updateView"
            :show_default_report_option="show_default_report_option"
          ></save-report>
        </div>

        <div class="filter-container" v-if="active_action === 'export'">
          <actions-panel-header @close="toggleActionsWindow('export')">
            <div class="mt-n1">
              <hb-icon
                small
                color="#101318"
                class="mr-2"
                mdi-code="mdi-download"
              >
              </hb-icon>
            </div>
            <div>Export Report</div>
          </actions-panel-header>
          <export-report
            v-if="report.filters"
            :selected="report"
            :contact_id="contact?.id"
            @close="toggleActionsWindow('export')"
          ></export-report>
        </div>

        <div class="filter-container" v-if="active_action === 'advanced'">
          <actions-panel-header @close="toggleActionsWindow('advanced')">
            <div style="position: relative; top: 2px">
              <hb-icon
                small
                color="#101318"
                class="mr-2"
                mdi-code="mdi-table-actions-custom-3"
              >
              </hb-icon>
            </div>
            <div>Advanced</div>
          </actions-panel-header>
          <advanced
            v-if="report.filters"
            :structure="structure.column_structure"
            :columns="report.filters.columns"
            :groups="report.filters.groups"
            :pivot="report.filters.pivot_mode"
            @setPivot="setPivot"
            @close="toggleActionsWindow('advanced')"
          ></advanced>
        </div>
        <!-- Added by BCT -->
        <multiple-property-selected-modal
          :bulk-edit="true"
          v-if="showEditMultiplePropertyDialog"
          v-model="showEditMultiplePropertyDialog"
          @close="
            (showEditMultiplePropertyDialog = false), (isBulkEditActive = false)
          "
        ></multiple-property-selected-modal>
        <div class="filter-container" v-if="active_action === 'bulk_edit'">
          <actions-panel-header
            @close="
              toggleActionsWindow('bulk_edit'), (isBulkEditActive = false)
            "
          >
            <div class="mt-n1">
              <hb-icon
                medium
                color="#101318"
                class="mr-2"
                mdi-code="mdi-square-edit-outline"
              >
              </hb-icon>
            </div>
            <!-- Added by BCT -->
            <div
              v-if="['spaces','tenant_rent_management', 'review_rent_changes', 'move_in'].includes(report_type)"
              :class="{ 'd-flex': selected.length }"
            >
              Update {{ getReportBaseLabel }}
              <hb-tooltip v-if="selected.length > 0">
                <template v-slot:item>
                  <span class="underline ml-1"
                    >({{ tenantCount ? tenantCount : selected.length }} {{ getReportBaseLabel }})</span
                  >
                </template>
                <template v-slot:body>
                  <div style="max-width: 300px">
                    {{ selectedSpaceNumbers }}
                  </div>
                </template>
              </hb-tooltip>
              <span class="underline ml-1" v-else
                >({{ selected.length }} {{ getReportBaseLabel }})</span
              >
            </div>

            <div
              v-else-if="report_type === 'property_promotion_management'"
              :class="{ 'd-flex': selected.length }"
            >
              Bulk Assign Promotion Plans
              <hb-tooltip v-if="selected.length > 0">
                <template v-slot:item>
                  <span class="underline ml-1"
                    >({{ selected.length }} Space Groups)</span
                  >
                </template>
                <template v-slot:body>
                  <div style="max-width: 300px">
                    {{ selectedSpaceNumbers }}
                  </div>
                </template>
              </hb-tooltip>
              <span class="underline ml-1" v-else
                >({{ selected.length }} {{ getReportBaseLabel }})</span
              >
            </div>
            <div
              v-else-if="report_type === 'property_apw_management'"
              :class="{ 'd-flex': selected.length }"
            >
              Bulk Advanced Reservations and Rentals Plan
              <hb-tooltip v-if="selected.length > 0">
                <template v-slot:item>
                  <span class="underline ml-1"
                    >({{ selected.length }} Space Groups)</span
                  >
                </template>
                <template v-slot:body>
                  <div style="max-width: 300px">
                    {{ selectedSpaceNumbers }}
                  </div>
                </template>
              </hb-tooltip>
              <span class="underline ml-1" v-else
                >({{ selected.length }} {{ getReportBaseLabel }})</span
              >
            </div>

            <div v-else class="bulk-communication-title">
                Bulk Actions
                <span v-if="selected.length > 0">
                  ({{ tenantCount ? tenantCount : selected.length }} {{this.report_type === 'active_national_accounts' ? 'Account' : 'Tenant'}}{{ (tenantCount || selected.length) === 1 ? '' : 's' }})
                </span></div>
          </actions-panel-header>
          <bulk-edit
            v-if="report.filters"
            :items="selected"
            :config="report.filters"
            :template="report_type"
            @disableCheckboxes="manageCheckboxes"
            @close="
              toggleActionsWindow('bulk_edit'), (isBulkEditActive = false)
            "
          ></bulk-edit>
        </div>

        <div class="filter-container" v-if="active_action === 'report_options'">
          <actions-panel-header
          @close="
            toggleActionsWindow('report_options')
          "
          >
            <div class="mt-n1">
              <hb-icon 
                :small="true" 
                color="#101318" 
                class="mr-2"
                mdi-icon="mdi-poll-box-outline"
              ></hb-icon
              >
            </div>
            <!-- Added by BCT -->
            <div>
              Data Viewer Options
            </div>
          </actions-panel-header>
          <report-options
            :config="report.filters"
            :template="report_type"
            :report="report"
            :property="property"
            @close="toggleActionsWindow('report_options')"
          ></report-options>
        </div>

        <div class="filter-container" v-if="active_action === 'unit_view'">
          <unit-view
            :unit_id="unit_id"
            :report-type="reportType"
            :show="getUnitDefaultView"
            @close="toggleActionsWindow('unit_view')"
            :margin-fix="false"
          ></unit-view>
        </div>

        <div class="filter-container" v-if="active_action === 'contact_view'">
          <contact-view
            :contact_id="contact_id"
            @close="toggleActionsWindow('contact_view')"
          ></contact-view>
        </div>

        <div
          class="filter-container"
          v-if="
            active_action === 'filters' &&
            report.filters &&
            structure.filter_structure
          "
        >
          <actions-panel-header @close="toggleActionsWindow('filters')">
            <div class="mt-n1">
              <hb-icon
                small
                color="#101318"
                class="mr-2"
                mdi-code="mdi-filter-variant"
              >
              </hb-icon>
            </div>
            <div>Filters</div>
          </actions-panel-header>
          <!-- TODO Check this -->
          <!--                    :filter_data="report.filter_data" -->
          <filters
            :filter_structure="structure.filter_structure"
            :filters="report.filters"
            @close="toggleActionsWindow('filters')"
            @search="setSearchParams"
          >
          </filters>
        </div>
      </v-col>
    </v-row>

    <!-- Content Area for Map Tab -->
    <v-row
      no-gutters
      class="grid-container"
      style="flex: 1; position: relative"
      v-if="tab === 'map'"
    >
      <v-progress-linear
        :indeterminate="isLoading"
        color="#00848E"
        class="mapProgressBar"
        v-if="spacesWithCoordinates.length !== 0"
      ></v-progress-linear>
      <MapViewer
        :spacesWithCoordinates="spacesWithCoordinates"
        :assets="assets"
        :floors="floors"
        :currentFloor="parseInt(currentFloor)"
        :spaceCount="spaceCount"
        :mapInitialLoad="mapInitialLoad"
        ref="MapViewer"
      />
    </v-row>

  <!-- Content Area for Space Type Tab -->
  <v-row
      no-gutters
      class="grid-container"
      style="flex: 1; position: relative"
      v-if="tab === 'space_groups'">
      <v-col v-if="searchRateReportForProperty">
        <ReportViewerV2 ref="HBReportViewer" :options="consolidatedOptions" />
      </v-col>
      <v-col v-else>
        <EmptyStateContainer
          message="Please select a Property"
        />
      </v-col>
    </v-row>

    <!-- BCT: Added Master filter HB-71 -->
    <div v-if="showMasterFilterModal && tab === 'list'">
      <master-filter
        :title="report_type === 'spaces' ? 'Filter Spaces' : 'Filter'"
        :data="table_data"
        :columns="columns"
        :reportId="report.id"
        :reportTemplate="report.template"
        @masterFilterEvt="onApplyMasterFilter"
        @close="showMasterFilterModal = false"
      >
      </master-filter>
    </div>
    <div v-else-if="showMasterFilterModal && tab === 'map'">
      <master-filter
        :title="report_type === 'spaces' ? 'Filter Spaces' : 'Filter'"
        :data="table_data"
        :columns="propertyMapFilters"
        :reportId="null"
        :reportTemplate="report.template"
        @masterFilterEvt="onApplyPropertyMap"
        @close="showMasterFilterModal = false"
      >
      </master-filter>
    </div>

    <!-- BCT: HB-67 Add Spaces - Dialog to add_spaces. -->
    <add-space-modal
      v-if="addSpace"
      v-model="addSpace"
      @close="
        addSpace = false;
        isAddSpacesActive = false;
      "
    ></add-space-modal>
    <!-- BCT: HB-67 Add Spaces - Dialog on add_space when multiple facility/property are selected. -->
    <multiple-property-selected-modal
      v-if="showMultiplePropertyDialog"
      v-model="showMultiplePropertyDialog"
      @close="
        showMultiplePropertyDialog = false;
        isAddSpacesActive = false;
      "
    ></multiple-property-selected-modal>

    <send-message
      v-model="sendMessageDialog"
      v-if="sendMessageDialog"
      :contacts="selected"
      :contact="actions.props.contact_id"
      @close="actions.type = null"
    ></send-message>

    <SendSpaceInfo
      v-model="sendSpaceInfoModal"
      v-if="sendSpaceInfoModal"
      @close="actions.type = null"
    ></SendSpaceInfo>

    <add-interaction
      v-model="addInteractionModal"
      v-if="addInteractionModal"
      :contact_id="actions.props.contact_id"
      @close="actions.type = null"
    ></add-interaction>

    <maintenance-request-view
      v-model="viewMaintenanceModal"
      v-if="viewMaintenanceModal"
      :maintenance_id="actions.props.maintenance_id"
      @close="actions.type = null"
    >
    </maintenance-request-view>

    <!--  // Restricted Permission warning popup -->
    <hb-modal
      v-model="showNoPermissionDialog"
      size="medium"
      title="Unable to Add Spaces"
      :footerOff="true"
      confirmation
      @close="showNoPermissionDialog = false"
      show-help-link
    >
      <template v-slot:content>
        <div class="px-6 py-4">
          <div class="pb-2">
            The signed in account does not have permission to add spaces.
          </div>
          <div>
            To add spaces, contact your administrator to enable permission.
          </div>
        </div>
      </template>
    </hb-modal>
    <hb-modal
    v-model="showNoPermissionDialogNationalAccount"
    size="medium"
    title="Unable To Add National Accounts"
    :footerOff="true"
    confirmation
    show-help-link
    @close="showNoPermissionDialogNationalAccount = false"
  >
    <template v-slot:content>
      <div class="px-6 py-4">
        <div class="pb-2">The signed in account does not have permission to add national accounts.</div>
        <div>To add national accounts, contact your administrator to enable permission.</div>
      </div>
    </template>
  </hb-modal>
    <!-- Restricted Permission warning popup // -->
  </div>
</template>


<script type="text/babel">
import api from "../../assets/api.js";
import Loader from "./CircleSpinner.vue";
import ReportViewerV2 from "../generics/AG/ReportViewerV2.vue";
import moment from "moment";
import Status from "../includes/Messages.vue";
import { EventBus } from "../../EventBus.js";
import SaveReport from "../includes/ReportFunctions/SaveReport.vue";
import DownloadUpload from "../includes/ReportFunctions/DownloadUpload.vue";
import Columns from "../includes/ReportFunctions/Columns.vue";
import Advanced from "../includes/ReportFunctions/Advanced.vue";
import BulkEdit from "../includes/ReportFunctions/BulkEdit.vue";
import ReportOptions from "../includes/ReportFunctions/ReportOptions.vue";
import ExportReport from "../includes/ReportFunctions/ExportReport.vue";
import Filters from "../includes/ReportFunctions/Filters.vue";
import AddInteraction from "../contacts/AddInteraction.vue";
import SendMessage from "../includes/SendMessage.vue";
import SendSpaceInfo from "../includes/SendSpaceInfo.vue";
import MaintenanceRequestView from "../maintenance/MaintenanceRequestView.vue";
import ActionsPanelHeader from "../includes/ReportFunctions/ActionsPanelHeader.vue";
import UnitView from "../units/UnitView.vue";
import ContactView from "../includes/ReportFunctions/ContactView.vue";
import MapViewer from "../property_map/MapViewer.vue";
import EmptyStateContainer from "../assets/EmptyStateContainer.vue";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

// import moment from 'moment';
import { BIToolMixin } from "../../mixins/bi_tool";
// BCT: HB-67 Add Spaces imports
import AddSpaceModal from "./spaceManagementComponents/AddSpaceModal.vue";
import MultiplePropertySelectedModal from "./spaceManagementComponents/MultiplePropertySelectedModal.vue";
import MasterFilter from "../includes/ReportFunctions/MasterFilter.vue";
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import SpaceGroupActions from "../units/SpaceGroupActions.vue";


export default {
  name: "HummingbirdTable",
  components: {
    ReportViewerV2,
    Status,
    Filters,
    SaveReport,
    DownloadUpload,
    Columns,
    Advanced,
    BulkEdit,
    ReportOptions,
    ExportReport,
    SendMessage,
    SendSpaceInfo,
    MaintenanceRequestView,
    AddInteraction,
    ActionsPanelHeader,
    UnitView,
    ContactView,
    AddSpaceModal,
    MultiplePropertySelectedModal,
    MasterFilter,
    Loader,
    EmptyStateContainer
  },
  props: ['contact', 'isMultipleEdit', 'activeTab'],
  data: () => ({
    // BCT: HB-67 Add Spaces
    // isLoading: false,
    addSpace: false,
    showMultiplePropertyDialog: false,
    showEditMultiplePropertyDialog: false,
    showNoPermissionDialog: false,
    showNoPermissionDialogNationalAccount:false,
    showMasterFilterModal: false,
    columns: [],
    isAddSpacesActive: false,
    isBulkEditActive: false,
    tab: "list",
    spaces: [],
    assets: [],
    floors: [],
    currentFloor: 1,
    spacesWithCoordinates: [],
    mapFilters: {},
    spaceCount: 1,
    currentProperty: "",
    mapProperties: [],
    mapExportAvailable: false,
    mapInitialLoad: false,
    dataFetchingError: "",
    propertyMapFilters: [
      {
        label: "Space Type",
        key: "unit_type",
        sortable: true,
        width: 118,
        column_type: "string",
        agg_method: "Count",
        format: "",
        fixed: false,
        input: "multi-select",
        options: [],
        search: [],
        group: "space",
      },
      {
        label: "Space Size",
        key: "unit_size",
        sortable: true,
        width: 113,
        column_type: "string",
        agg_method: "Count",
        format: "",
        fixed: false,
        input: "multi-select",
        options: [],
        search: [],
        group: "space",
      },
      {
        label: "Space Status",
        key: "unit_status",
        sortable: true,
        width: 168,
        column_type: "status",
        agg_method: "Count",
        format: "",
        fixed: false,
        input: "multi-select",
        options: [
          "Available",
          "Leased",
          "Pending",
          "On Hold",
          "Offline",
          "Reserved",
          "Overlocked",
          "Remove Overlock",
          "To Overlock",
          "Future Leased",
          "Deactivated",
        ],
        search: [],
        group: "space"
      }
    ],
    disableBulkEditForDynamicReport:true,
    searchRateReportForProperty: '',
    tenantCount: 0,
    spaceGroups:[]
  }),

  async created() {
    if(this.$route.path.includes("reports-library")){
      this.disableBulkEditForDynamicReport = false;
    }else{
      this.disableBulkEditForDynamicReport = true;
    }
    // BCT: Added field to check condition in framework component 'Header filter'
    this.componentName = "HummingbirdReportViewer";
    this.gridOptions.rowModelType = "serverSide";

    EventBus.$on("unit_edited", this.eventFilterChangedHandler);
    EventBus.$on("contact_updated", this.eventFilterChangedHandler);
    EventBus.$on("lease_payment_saved", this.eventFilterChangedHandler);
    EventBus.$on("interaction_saved", this.eventFilterChangedHandler);
    EventBus.$on("lead_saved", this.eventFilterChangedHandler);
    EventBus.$on("payment_saved", this.eventFilterChangedHandler);
    EventBus.$on("search_filter", this.eventFilterChangedHandler);
    EventBus.$on("unit_edited_single", this.updateSelectedUnit);
    EventBus.$on(
      "unit_amenities_edited_single",
      this.updateSelectedUnitAmenities
    );
    EventBus.$on("space_generation_update", this.handleSpaceCreation);
    EventBus.$on("handle_select_all", this.handleSelectAll);
    EventBus.$on("upload_rent_change_update", this.uploadRentChange);
    if (this.isSpacesRoute) {
      this.tab = this.activeTab;
    }
    EventBus.$on("communicationCount", this.handleCommunicationCount);
    if (this.isMultipleEdit) {
      this.bulkEditSpaces('bulk_edit');
    }
  },
  mounted() {
    this.fetchPropertyList();
  },
  activated() {
    // Called every time the component is re-activated when using <keep-alive>
    this.handleCommunicationCount(this.handleCommunicationCount);
  },
  computed: {
    ...mapGetters({
      selectedProperties: "propertiesStore/filtered",
      hasPermission: "authenticationStore/rolePermission",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
      getTenantReportViews: 'reportStore/getTenantReportViews',
      hasInternalRateEngine: "revManStore/hasInternalRateEngine",
    }),
    // Added by BCT
     selectedSpaceNumbers() {
      let selected = this.selected.map((space) => { return (this.isMultipleEdit) ? space : space.unit_number });
      if(this.report_type === 'review_rent_changes')
        selected = this.selected.map((data) => data?.rentchange_unit_number);
      if (selected && selected.length > 0) {
        if(this.isMultipleEdit){
          return selected
        }
        return selected.join(", ");
      }
      return null;
    },
    nationalAccountMobileCondition() {
      return this.$vuetify.breakpoint.width <= 837 && this.reportType === 'national_account_unit_list'
    },
    nationalAccountListMobileCondition(){
      return this.$vuetify.breakpoint.width <= 837 && this.reportType === 'active_national_accounts'
    },
    getUnitDefaultView() {
      /*
        * Unit View will show Tenant Profile  as default tab when isTenentView(props) is true
        * Space info tab will be the default tab
      */
      return this.getTenantReportViews.includes(this.report_type)
        ? "tenant-profile"
        : "details";
    },
    reportType() {
      return this.$props.report_type
    },
    consolidatedOptions() {
      return {
        class: ["ag-theme-material", "p-absolute"],
        style: [{ width: "100%", height: "100%" }],
        props: {
          key: this.activated,
          columnDefs: this.gridOptions.columnDefs,
          defaultColDef: this.gridOptions.defaultColDef,
          rowSelection: this.gridOptions.rowSelection,
          suppressDragLeaveHidesColumns:
            this.gridOptions.suppressDragLeaveHidesColumns,
          suppressRowClickSelection: this.gridOptions.suppressRowClickSelection,
          suppressCellFocus: this.gridOptions.suppressCellFocus,
          rowMultiSelectWithClick: this.report_type === 'active_tenants' || this.report_type === 'spaces',
          rowModelType: this.gridOptions.rowModelType,
          cacheBlockSize: this.gridOptions.cacheBlockSize,
          maxBlocksInCache: this.gridOptions.maxBlocksInCache,
          overlayNoRowsTemplate: this.gridOptions?.overlayNoRowsTemplate ?? 
          (this.report_type === 'active_national_accounts' ? '<span style="font-size: 20px;padding: 10px;">No National Accounts</span>' : '<span style="font-size: 20px;padding: 10px;">No Spaces</span>'),
          getContextMenuItems: this.getContextMenuItems,
          suppressContextMenu: this.gridOptions.suppressContextMenu,
          rowClass: this.gridOptions.rowClass,
          rowClassRules: this.gridOptions.rowClassRules,
          blockLoadDebounceMillis: "400",
          statusBar: this.gridOptions.statusBar,
          serverSideSortOnServer: true,
          serverSideInfiniteScroll: true,
          // pagination: true,
          suppressAggFuncInHeader: true,
          ...(this.$props.gridConf?.props ?? {}),
        },
        listeners: {
          onDragStopped: this.handleDragStopped,
          onRowClicked: this.handleRowClick,
          onCellClicked: this.handleCellClick,
          onGridReady: this.onGridReady,
          onRowSelected: this.onRowSelected,
          onSelectionChanged: this.onSelectionChanged,
          onFilterChanged: this.onFilterChange,
          ...(this.$props.gridConf?.listeners ?? {}),
          /**
           * onKebabClick: this.onKebabClick,
           * onInfoClick: this.onInfoClick
           */
        },
      };
    },
    isSpacesRoute() {
      return this.$route.name && this.$route.name.toLowerCase() === `spaces`;
    },
    activeTabValue() {
      switch (this.activeTab) {
        case 'list':
          return 0
        case 'map':
          return 1
        case 'space_groups':
          return 2
      }
    }
  },

  methods: {
  ...mapActions({
            removeGlobalNotificationByType: "globalNotificationsStore/removeGlobalNotificationByType",
            setProperty: "revManStore/setProperty",
            addBulkUnits: 'reportStore/addBulkUnits',
          }),
    fetchPropertyList() {
      this.mapProperties = this.selectedProperties;
      if (this.mapProperties.length && this.mapInitialLoad) {
        this.currentProperty = this.mapProperties[0].id;
        this.updateMap();
      }
    },
    addNationalAccount(){
      if(!(this.hasPermission("manage_national_accounts"))){
        this.showNoPermissionDialogNationalAccount = true
        return
      }
      EventBus.$emit("openNationalAccount", true);
    },
    updateMap() {
      let _this = this;
      api
        .get(this, api.PROPERTY_MAP + this.currentProperty + "/searchUnits")
        .then((res) => {
          let { units } = res;
          _this.spaces = units;
          _this.spacesWithCoordinates = units
            .filter((space) => space.y !== null && space.x !== null)
            .map((v) => {
              let space = { ...v, color: "#919EAB", filterMatch: true };
              if (space.floor !== null && !isNaN(parseInt(space.floor))) {
                space.floor = parseInt(space.floor);
              }
              return space;
            });
          _this.setFloors();
          _this.updateSpaceCount();
          _this.mapInitialLoad = true;
          _this.isLoading = false;
          setTimeout(() => {
            this.$refs.MapViewer.zoomReset();
          },10);
        });
      api
        .get(this, api.PROPERTY_MAP + this.currentProperty + "/assets")
        .then((res) => {
          let { assets } = res;
          this.assets = assets;
          this.spacesWithCoordinates = assets.filter(
            (asset) => asset.x && asset.y
          );
          this.assets = res.assets;
        });
    },
    changeProperty(ev) {
      this.isLoading = true;
      if (ev.id) {
        this.currentProperty = ev.id;
        this.floors = [];
        this.updateMap();
      }
    },
    changeFloor(ev) {
      this.currentFloor = parseInt(ev);
      this.updateSpaceCount();
    },
    setFloors() {
      let _this = this;
      _this.isLoading = true;
      this.floors = [
        ...new Set(
          this.spacesWithCoordinates
            .filter(
              (space) => space.floor !== null && !isNaN(parseInt(space.floor))
            )
            .map((space) => parseInt(space.floor))
            .sort()
        ),
      ];
      this.currentFloor = this.floors.length ? parseInt(this.floors[0]) : 1;
    },
    tabChange(tab) {
      if (this.isSpacesRoute) {
        if (this.tab === 'space_groups') {
            this.$store.commit("reportStore/setIsRateReport", false);
            this.$store.commit("reportStore/setDynamicRunParam", {
              propertyIDArray: undefined
            })
            this.$store.commit("reportStore/setSelectedSpaceGroup", {});             
        }
        this.$parent.activeTab = tab;
        if(tab === 'space_groups') {
          this.$store.commit("reportStore/setIsSpaceGroupReport", true);
          this.$parent.report_type = 'property_rate_management';
        } else {
          this.$store.commit("reportStore/setIsSpaceGroupReport", false);
          this.$parent.report_type = 'spaces';
        }
        this.$parent.report_key++;
      }
      this.tab = tab;
      this.$emit('onTabChange', this.tab)
    },
    updateSpaceCount() {
      this.spaceCount = this.spacesWithCoordinates.filter(
        (space) =>
          space.filterMatch === true && space.floor == this.currentFloor
      ).length;
    },
    /**
     * BCT - HB-67 Add spaces
     * Checks for multiple properties and accordingly show addSpace Modal.
     */
    addSpaces() {
      this.isAddSpacesActive = true;
      if (this.selectedProperties && this.selectedProperties.length > 1) {
        this.showMultiplePropertyDialog = true;
      } else if (this.hasPermission("add_spaces")) {
        this.addSpace = true;
      } else {
        this.showNoPermissionDialog = true;
      }
    },
    /**
     * BCT - Bulk Edit
     * Checks for multiple properties and accordingly show bulk edit Modal.
     * If there are multiple properties, Checks value for 'bulkEditProperty' -> used for bulk edit when there's a seperate property selector other than global.
     */
    bulkEditSpaces() {
      if (this.report_type === 'active_national_accounts') {
        this.toggleActionsWindow("bulk_edit");
      }
      else {
        if (this.isMultipleEdit) {
          this.toggleActionsWindow("bulk_edit");
          this.isBulkEditActive = false;
        }else if (this.selectedProperties && this.selectedProperties.length > 1) {
          if (this.bulkEditProperty) {
            this.toggleActionsWindow("bulk_edit");
            this.isBulkEditActive = false;
            return;
          }
          this.showEditMultiplePropertyDialog = true;
          this.isBulkEditActive = true;
        } else {
          this.toggleActionsWindow("bulk_edit");
          this.isBulkEditActive = false;
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.showLoadingOverlay(this);
      this.columnApi = params.columnApi;
      this.gridApi.setServerSideDatasource(this);
      this.resetGridRowsCount();
      if (this.report.filters.search) {
        if (this.$props.conditions && this.$props.conditions.filters) {
          if (
            this.report.filters.search.rate_change_id &&
            !this.$props.conditions.filters.rate_change_id
          ) {
            delete this.report.filters.search.rate_change_id;
          }
          Object.assign(
            this.report.filters.search,
            this.$props.conditions.filters
          );
        }
        this.searchField = this.report.filters.search.search;
      }
      if (
        this.report.filters &&
        this.report.filters.sort &&
        this.report.filters.sort.field
      ) {
        var sort = {
          state: [
            {
              colId: this.report.filters.sort.field,
              sort: this.report.filters.sort.dir || "asc",
            },
          ],
          defaultState: { sort: null },
        };
        this.columnApi.applyColumnState(sort);
      }
      this.grid_loaded = true;
      /**
       * Emit grid api to the callee
       */
      this.$emit("gridReady", params.api);
    },
    eventFilterChangedHandler() {
      if (this.gridApi) {
        this.gridApi.onFilterChanged();
        this.gridApi.hideOverlay();
      }
    },
    updateSelectedUnit(unit) {
      if (unit && unit.unit_id) {
        this.gridApi.forEachNode(function (rowNode) {
          if (rowNode.data.unit_id === unit.unit_id) {
            rowNode.setData(unit);
          }
        });
      }
    },
    // added by BCT
    // This is to update 'unit_amenities' column whenever user updates Features and amenities from Space Profile
    // This needs to be updated if made any changes for 'unit_amenities' column in backend
    updateSelectedUnitAmenities(updatedAmenities, unit_id) {
      const data = [];
      Object.entries(updatedAmenities).forEach((amenities) => {
        amenities[1].forEach((amenity) => {
          if (
            amenity.property_default_value &&
            amenity.property_default_value !== "No"
          ) {
            data.push(amenity);
          }
        });
      });
      const unit_amenities = data
        .sort((a, b) => a.property_sort_order - b.property_sort_order)
        .map((amenity) => {
          if (amenity.property_default_value === "Yes")
            return amenity.property_amenity_name;
          return amenity.property_default_value;
        })
        .join(",");

      this.gridApi.forEachNode(function (rowNode) {
        if (
          rowNode.data.unit_id === unit_id &&
          "unit_amenities" in rowNode.data
        ) {
          rowNode.setData({ ...rowNode.data, unit_amenities });
        }
      });
    },
    handleSpaceCreation(data) {
              this.removeGlobalNotificationByType({ type: 'create_spaces' })
              switch(data.state) {
                case "Ready":
                  this.showMessageNotification({title: 'Adding Spaces', type: 'create_spaces', loading: true, description: 'We are adding all of the entered spaces, this may take a few seconds.',});
                  break;
                case "Error":
                  this.showMessageNotification({type: 'error', description: 'Internal Configuration Error: Please contact your administrator for adding spaces.'});
                  break;
                case "Done":
                  this.showMessageNotification({type: 'success', description: data?.message ?? 'Your Spaces are ready.'});
                  this.eventFilterChangedHandler();
                  break;
              }
            },

    // BCT: Set columns for Master filter
    showMasterFilter() {
      this.columns = JSON.parse(JSON.stringify(this.report.filters.columns));
      this.showMasterFilterModal = true;
    },

    // BCT: Notify to grid about filter data changed.
    onApplyMasterFilter() {
      this.gridApi.onFilterChanged();
    },

    async onApplyPropertyMap(search) {
      let mapFilters = {};
      for (var key of Object.keys(search)) {
        mapFilters[key] = search[key].column.search;
      }
      let { unit_size, unit_status, unit_type } = mapFilters;
      this.spacesWithCoordinates.forEach((space, i) => {
        // Filtering: A space will be a match (and not filtered out) if
        // 1. A filter category the space is being compared to has nothing to filter against
        // 2. The space attribute (size,status,type) matches one of the filter options for that filter category
        // If a space matches `true` for all filter categories then it is a match and not deemed 'filtered-out'.
        let matchesSizeFilter =
            !unit_size.length || unit_size.indexOf(space.label) > -1,
          matchesStatusFilter =
            !unit_status.length || unit_status.indexOf(space.state) > -1,
          matchesTypeFilter =
            !unit_type.length || unit_type.indexOf(space.type) > -1;
        this.spacesWithCoordinates[i].filterMatch =
          matchesSizeFilter && matchesStatusFilter && matchesTypeFilter;
        return;
      });
      this.updateSpaceCount();
    },

    //BCT: TI-10489
    back() {
      if (this.$route.query && this.$route.query.dynamicRun) {
        return this.$router.push("/reports-library");
      }
      this.$router.go(-1);
    },

    uploadRentChange(data) {
      if (data.state === "success" || data.state === "error") {
        this.$store.commit("notificationsStore/updateDocumentDownload", {
          id: this.selectedProperties[0].id,
          success: !!(data.state === "success"),
          ...data.payload
        });
        if (data.state === "success"){
          EventBus.$emit("reviewRentChangeEvents", {
              event: "refreshTable",
          });
          EventBus.$emit("rentChangeQueueEvents", "refreshTable");
          EventBus.$emit("tenantRentManagementEvent", {
            event: "refreshTable",
          });
          data.state = null
        }

      } else {
        if (data.state) {
          this.$store.commit("notificationsStore/startProcessRentChanges", {
          id: this.selectedProperties[0].id,
          message: data.message
        });
        }
      }
    },
    
    showErrorNotification(err){
      this.showMessageNotification({type: 'error', description: err.description});
      this.dataFetchingError = "";
    },
    async fetchSpaceGroups(params){
      this.$store.commit("reportStore/setSelectedSpaceGroup", {});
      this.searchRateReportForProperty = '';
      this.$store.commit("reportStore/setIsRateReport", false);
      this.$store.commit("reportStore/setIsSpaceGroupReport", true);
      let r = await api.get(this, api.PROPERTIES + params + "/space-groups");
      this.spaceGroups = r.spaceGroups;
      return true;
    },
    async searchRateReportEvent(params) {
      this.$store.commit("reportStore/setIsRateReport", true);
      this.$store.commit("reportStore/setIsSpaceGroupReport", false);
      this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: 'property_id' in params ? [params.property_id] : undefined
      });
      //setting spacegroup
      this.$store.commit("reportStore/setSelectedSpaceGroup", { 
        ...params
      })

      if(!this.searchRateReportForProperty || this.searchRateReportForProperty == '' || this.searchRateReportForProperty == undefined) this.searchRateReportForProperty = params;
      else await this.eventFilterChangedHandler();
      this.searchRateReportForProperty = params.property_id; 
    },
    handleCommunicationCount(data) {
    if (data.selectedAll) {
      this.tenantCount = data.totalCount;
    }
    else{
      this.tenantCount = 0;
    } 
  }
  },
  mixins: [BIToolMixin, notificationMixin],
  beforeDestroy() {
    EventBus.$off("unit_edited", this.eventFilterChangedHandler);
    EventBus.$off("contact_updated", this.eventFilterChangedHandler);
    EventBus.$off("lease_payment_saved", this.eventFilterChangedHandler);
    EventBus.$off("interaction_saved", this.eventFilterChangedHandler);
    EventBus.$off("lead_saved", this.eventFilterChangedHandler);
    EventBus.$off("payment_saved", this.eventFilterChangedHandler);
    EventBus.$off("search_filter", this.eventFilterChangedHandler);
    EventBus.$off("unit_edited", this.updateSelectedUnit);
    EventBus.$off(
      "unit_amenities_edited_single",
      this.updateSelectedUnitAmenities
    );
    EventBus.$off("space_generation_update", this.handleSpaceCreation);
    EventBus.$off("handle_select_all", this.handleSelectAll);
    EventBus.$off("upload_rent_change_update", this.uploadRentChange);
    EventBus.$off("selectAllTenants", this.uploadRentChange);
    EventBus.$off("communicationCount", this.handleCommunicationCount);
    this.addBulkUnits([]);
  },
  watch: {
    "$route.query.id"() {
      this.view = this.views.find((v) => v.id === this.$route.query.id);
      this.changeView();
    },
    dataFetchingError(e){
      if(e) this.showErrorNotification({description: e});
    },
    tab(val){
      if(val == 'space_groups'){
        this.$props.gridConf.props = {
            BIHelpers: {
                kebab: {
                    enabled: true,
                    icon: "mdi-dots-vertical",
                    component: {
                        definition: SpaceGroupActions,
                        props: {
                            propertyId: this.property,
                        },
                    },
                    action: "click",
                },
            },
            getRowId: (params) => params.data.spacegroup_id_hash,
            defaultColDef: {
                floatingFilter: false,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            getRowHeight: (params) => params?.data?.spacegroup_summary ? 60 : 48,
        };
      }else{
        this.$props.gridConf.props = {};
      }
    },
    selectedSpaceNumbers(newValue) {
      this.$emit('update-selected-space-numbers', {selected: newValue, unCheckedItem: this.un_checked_item}); // Emit the value to the parent
    },
    tab(val){
      if(val == 'space_groups'){
        this.$props.gridConf.props = {
            BIHelpers: {
                kebab: {
                    enabled: true,
                    icon: "mdi-dots-vertical",
                    component: {
                        definition: SpaceGroupActions,
                        props: {
                            propertyId: this.searchRateReportForProperty,
                        },
                    },
                    action: "click",
                },
            },
            getRowId: (params) => params.data.spacegroup_id_hash,
            defaultColDef: {
                floatingFilter: false,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            },
            getRowHeight: (params) => params?.data?.spacegroup_summary ? 60 : 48,
        };

        this.$props.columnConf = {
          spacegroup_rate_management_active: {
            cellRenderer: "BooleanCellRenderer",
            suppressNavigable: true,
            hide: !this.hasInternalRateEngine,
            cellRendererParams: {
              icon: true,
            },
            cellClass: "boolean-icons",
          },
          spacegroup_rate_plan_name: {
            suppressNavigable: true,
            cellRenderer: "PlanCellRenderer",
            hide: !this.hasInternalRateEngine,
            cellRendererParams: {
              type: "rate",
              unit_type_key: "spacegroup_category",
              price_delta_type_key: "spacegroup_rate_plan_change_type",
            },
          },
          spacegroup_spacetype: {
            autoWidth: true,
            cellRenderer: (params) => this.getUnitTypeTitle({unit_type_id:params?.data?.spacegroup_spacetype_id}),
            pinned: "left",
          },
          spacegroup_size: {
            autoWidth: true,
            pinned: "left",
          },
          spacegroup_amenities: {
            autoWidth: true,
            pinned: "left",
          },
        };
      }else{
        this.$props.gridConf.props = {};
        this.$props.columnConf = {};
      }
    },
    // Watcher for searchRateReportForProperty
    async searchRateReportForProperty(newValue) {
      if(newValue != '' || newValue != undefined){
        this.setProperty(newValue);
        this.$store.commit("reportStore/setDynamicRunParam", {
        propertyIDArray: [newValue]
      });
      this.$props.gridConf.props = {
        BIHelpers: {
          kebab: {
            enabled: true,
            icon: "mdi-dots-vertical",
            component: {
              definition: SpaceGroupActions,
              props: {
                propertyId: newValue, // Update with the new value
              },
            },
            action: "click",
          },
        },
        getRowId: (params) => params.data.spacegroup_id_hash,
        defaultColDef: {
          floatingFilter: false,
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        getRowHeight: (params) => params?.data?.spacegroup_summary ? 60 : 48,
        };
      }
    }
  }
};
</script>

<style lang="scss">
  
  .report-viewer-container {
    > div:nth-child(1) {
      > span {
        > div {
          > div {
            > div {
              > .national-account-select-property-wrapper {
                width: 48%!important;
                margin-bottom: 6px !important;
                padding-right: 4px!important;
              }
              > div:nth-child(2) {
                margin-bottom : 0px!important;
              }
            }
          }
        }
      } 
    }
  }
  .report-viewer-container {
    > div:nth-child(1) {
      > span {
        > div {
          > div {
            > div {
              > div:nth-child(2) {
                display: block!important;
              }
            }
          }
        }
      } 
    }
  }

  .national-account-wrapper-width {
    width: 48%!important;
  }
  @media (min-width: 385px) and (max-width: 486px) {
      button {
        span.hb-button-text-regular {
        font-size: 13px !important;
        }
  }
}

  .report-viewer-container {
    > div:nth-child(1) {
      > span {
        > div {
          > div {
            > div {
              > div:nth-child(2) {
                display: block!important;
              }
            }
          }
        }
      } 
    }
  }

  .national-account-wrapper-width {
    width: 48%!important;
  }
  
  @media (min-width: 385px) and (max-width: 700px) {
    .report-viewer-container {
      > div:nth-child(1) {
        > span {
          > div {
            > div {
              > div {
                > .national-account-select-property-wrapper {
                  width: 44%!important;
                  margin-bottom: 6px !important;
                  padding-right: 4px!important;
                }
                > div:nth-child(2) {
                  margin-bottom : 0px!important;
                }
              }
            }
          }
        } 
      }
    }
  }

.spaces-tabs {
  max-height: 45px;

  &.theme--light.v-tabs > .v-tabs-bar {
    height: 45px;
    background-color: rgba(249, 250, 251, 0);
  }

  & .v-tabs-slider {
    color: #00848e;
  }
}

.spaces-map-header-bar {
  z-index: 9;

  & > .row > div {
    display: flex;
  }
}

.ag-header {
  // height: 50px !important;
  min-height: 50px !important;
  background: #f4f6f8;
}
// .ag-header-row {
//     height: 50px !important;
// }

.ag-header-row,
.ag-pinned-left-header {
  background-color: #f4f6f8;
  border-bottom: 2px solid #c6cdd4;
}

.ag-header-cell.ag-header-cell-sortable:hover {
  background-color: transparent !important;
  .ag-header-cell-text {
    color: #101318;
  }
}

.ag-header-cell-text {
  color: #474f5a;
  font-weight: 500;
  font-size: 12px;
}

.ag-header-cell-text:hover {
  color: #101318;
}

.report-viewer-container {
  display: flex;
  flex-direction: column;
  /*justify-content: space-evenly;*/
  height: 100%;
}
.ag-header-viewport {
  font-weight: 500;
  color: #474f5a;
  /*border-bottom: 1px solid #C6CDD4;*/
}
.ag-root-wrapper {
  /*border: 1px solid #C6CDD4;*/
  // box-shadow: 0px 0px 4px #ccc;
  border: none;
  box-shadow: none !important;
}
/*.grid-container{*/
/*    border: 1px solid #C6CDD4;*/
/*}*/
.grid-container {
  margin-top: 1px;
}
.filter-container {
  position: absolute;
  overflow-y: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  background-color: #F9FAFB !important;
  border-left: 1px solid #DFE3E8;
  display: flex;
  flex-direction: column;
}
.filter-container .filter-headline {
  font-size: 14px;
  padding: 16px 16px 15.5px;
  background-color: #f4f6f8;
  line-height: 21px;
}
.ag-theme-material .ag-header {
  border-bottom: none;
}

.ag-theme-material .ag-cell,
.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  line-height: 23px;
  padding-left: 17px;
  padding-right: 17px;
}
.ag-header-cell {
  line-height: 15px !important;
}
.ag-cell {
  font-size: 12px;
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}

.report_link {
  color: #306fb6;
  border-bottom: 1px dotted #306fb6;
  padding-bottom: 2px;
}


.ag-body-viewport {
  border-top: 1px solid #DFE3E8;
}
.ag-row.hummingbird-row {
  background-color: white;
}
.ag-row.hummingbird-tagged-row {
  background-color: #FFF9DA !important;
}
.ag-row.hummingbird-row.ag-row.hummingbird-tagged-row.ag-row-focus {
  background-color: #FFF9DA !important;
}
.ag-row.hummingbird-row.ag-row-hover.hummingbird-tagged-row {
  background-color: #eeeeee !important;
}
.ag-row.hummingbird-row.ag-row-hover.hummingbird-tagged-row.ag-row-focus {
  background-color: #eeeeee !important;
}

.ag-row.ag-row-focus.hummingbird-row,
.ag-row.ag-row-selected.hummingbird-row,
.ag-row.hummingbird-row:hover {
  background-color: #F9FAFB !important;
}
.ag-row.hummingbird-row.ag-row-focus {
  background-color: #ffffff !important;
}
.ag-row.hummingbird-row.ag-row-focus.ag-row-hover {
  background-color: #F9FAFB !important;
}

.ag-theme-material {
  --ag-selected-row-background-color: #E0F5F5;
}
/*
  .ag-header-cell-resize {
    border-left: 1px solid #F4F6F8;
    border-right: 1px solid #F4F6F8;
  }
  */

.ag-theme-material .ag-header:hover .ag-header-cell-resize {
  /*border-left: 1px solid #C6CDD4;*/
  /*border-right: 1px solid #C6CDD4;*/
  background: linear-gradient(#DFE3E8, #DFE3E8) no-repeat center/1px 100%;
}

.actions-panel {
  flex: 0 1;
}

.mask {
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ag-header-viewport,
.ag-floating-top-viewport,
.ag-body-viewport,
.ag-pinned-left-cols-viewport,
.ag-center-cols-viewport,
.ag-pinned-right-cols-viewport,
.ag-floating-bottom-viewport,
.ag-body-horizontal-scroll-viewport,
.ag-virtual-list-viewport {
  background-color: #f4f6f8;
}
.ag-center-cols-viewport {
  background: white;
}
.ag-center-cols-container {
  min-width: 100%;
}
.p-absolute {
  position: absolute;
}

.ag-theme-material .ag-selection-checkbox:hover {
  cursor: hand;
}

.ag-theme-material .ag-icon-checkbox-unchecked::before {
  color: #dfe3e8;
}

.ag-theme-material
  .ag-selection-checkbox:hover
  .ag-icon-checkbox-unchecked::before {
  color: #637381;
}

.ag-theme-material .ag-icon-checkbox-checked {
  color: #00848e;
}

.ag-theme-material .ag-icon-checkbox-unchecked,
.ag-theme-material .ag-icon-checkbox-checked {
  vertical-align: top;
  left: -5px;
  bottom: 5px;
}

.underline {
  border-bottom: 1px dashed #000000;
  padding-bottom: 6px;
}

.v-progress-linear.mapProgressBar.theme--light {
  position: absolute;
  transform: translateY(-4px);
}
.ag-pinned-left-header {
  box-shadow: 4px 0px 10px rgba(71, 84, 116, 0.1) !important;
  border-bottom: none !important;
  z-index: 2;
}
.ag-pinned-right-header {
  box-shadow: -4px 0px 10px rgba(71, 84, 116, 0.1) !important;
  border-bottom: none !important;
}
.ag-pinned-left-cols-container {
  box-shadow: 4px 0px 10px rgba(71, 84, 116, 0.1) !important;
  z-index: 2;
}
.ag-pinned-right-cols-container {
  box-shadow: -4px 0px 10px rgba(71, 84, 116, 0.1) !important;
  z-index: 2;
}
.ag-checkbox-input-wrapper {
  width: 17px;
  height: 17px;
  border-radius: 2px;
  &.ag-checked {
    background-color: #00848e;
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border: solid 2px #dfe3e8;
    border-radius: 2px;
  }
  &:hover {
    &::after {
      border: solid 2px #637381;
    }
  }
  &.ag-checked::after {
    content: "";
    left: 5px;
    top: 0px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.ag-row-hover {
  background-color: #F9FAFB !important;
}
// .ag-cell-value {
//   display: flex;
//   align-items: center;
//   :not(.override-overflow) {
//     span {
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//     }
//   }
// }
.ag-cell-value.bi-helper-icons {
  span {
    overflow: visible;
  }
}
.ag-cell.ag-cell-value {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.non-cursor>span>.hb-link{
  cursor: auto !important;
}

.bulk-communication-title{
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
</style>
<style lang="scss" scoped>
  .full-width {
    width: 100%;
  }
</style>


<!--  https://codepen.io/ZeroX-DG/pen/vjdoYe - resizeable column -->
