<template>
    <div id="file-uploads" class="">
        <div class="doc-list" style="height: 699px" @scroll.self="fetchOnScroll">
            <v-card class="mb-4" :class="{ 'mr-6': showAside }" elevation="0">
                <v-data-table :headers="headers" :items="uploads" :loading="loadingDocuments"
                    loading-text="Loading Signed Documents..." class="hb-data-table" disable-pagination
                    hide-default-footer :custom-sort="customSort" @click:row="onCheckBoxClick">
                    <template v-slot:item.type="{ item }">
                        <span>{{ item.type }}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                        <span> {{ item.name }}</span>
                    </template>
                    <template v-slot:item.checkbox="{ item }" style="display: flex">
                        <v-checkbox v-model="selectedOption" :value="item" hide-details
                            class="square-checkbox"></v-checkbox>
                    </template>
                </v-data-table>
                <v-row class="mx-0" v-if="loadingDocuments">
                    <v-col md="12" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </div>
</template>
<script type="text/babel">
import { mapGetters, mapMutations, mapActions } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import api from "../../../assets/api.js";

export default {
    name: "UploadBulkUnsignedDoc",
    data() {
        return {
            previouslySelected: "",
            selectedOption: "",
            documents: [],
            document: {},
            hasSubmitted: false,
            uploads: [],
            lease: {},
            headers: [
                {
                    text: "",
                    value: "checkbox",
                    align: "left",
                    width: "50px",
                    sortable: false,
                },
                {
                    text: "Document Type",
                    value: "type",
                    width: "130px",
                    groupable: false,
                },
                {
                    text: "Document Name",
                    value: "name",
                    width: "200px",
                    groupable: false,
                },
            ],
            mainView: {},
            dateRange: "",
            customStartDate: "",
            customEndDate: "",
            todaysDate: "",
            loadingDocuments: false,
            allowScrollRequest: true,
            loadingDocumentTemplates: false,
            documentMethod: "un-signed",
            documentType: { value: "all" },
            isDocumentReset: false,
            document_generate_type: "",
            fetchUnSignedDocumentUsingScroll: false,
            documentText: "Loading Signed Documents...",
            isAllRecordsLoaded: false,
        };
    },
    mixins: [notificationMixin],
    async created() {
        this.fetchData();
    },
    destroyed() {
        this.selectedOption = "";
    },
    methods: {
        interactionsCount() {
            return this.uploads.length ? this.uploads.length : 0;
        },
        async fetchData() {
            this.loadingDocuments = true;
            let params
            let limit = 30;
            let offset =
                this.uploads.length == 0
                    ? 1
                    : Math.ceil(this.uploads.length / limit) + 1;
            if (this.mainView.property_id) {
                params = `?limit=${limit}&offset=${offset}&document_type=un-signed&property_ids[]=${this.mainView.property_id ? this.mainView.property_id : ''}`;
            } else {
                params = `?limit=${limit}&offset=${offset}&document_type=un-signed`;
            }
            let path = "";

            api
                .get(this, api.DOCUMENT + params + path)
                .then((r) => {
                    if (r.documents.length == 0) {
                        this.allowScrollRequest = false;
                        this.documentText = "No data found.";
                    }
                    else if (r.documents.length < 30) {
                        this.allowScrollRequest = false;
                    }
                    else {
                        this.allowScrollRequest = true;
                    }
                    this.tempDocs = [...this.uploads, ...r.documents];
                    this.uploads = this.tempDocs;
                    this.loadingDocuments = false;
                })
                .catch((err) => {
                    this.showMessageNotification({ description: err });
                    this.loadingDocuments = false;
                });
        },
        fetchOnScroll(elem) {
            let { target: { scrollTop, clientHeight, scrollHeight }} = elem;
            let scrolledValue = Math.round(scrollTop + clientHeight);
            if (scrolledValue >= scrollHeight) {
                const length = this.uploads.length;
                this.isAllRecordsLoaded = length % 30 == 0 ? false : true;
                if (
                    !this.loadingDocuments &&
                    !this.isAllRecordsLoaded && this.allowScrollRequest
                ) {
                    this.fetchData();
                }
            }
        },
        onCheckBoxClick(item) {
            console.log('_________________', item.id);
            console.log('boooooooolean', this.previouslySelected);

            if(this.previouslySelected == item) {
                this.$emit('selected-document-id', {})
                this.previouslySelected = "";
                this.selectedOption = "";
            }else{
                this.$emit('selected-document-id', {id : item.id, name : item.name, type : 'unsigned'});
                this.previouslySelected = item;
                this.selectedOption = item;
            }
        },
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] === "name") {
                    var fa = "";
                    if (a.id && a.name) {
                        fa = a.name
                    } else {
                        ("");
                    }

                    var fb = "";
                    if (b.id && b.name) {
                        fb = b.name;
                    } else {
                        ("");
                    }
                    if (!isDesc[0]) {
                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
                if (index[0] === "type") {
                    var fa = "";
                    if (a.id && a.type) {
                        fa = a.type
                    } else {
                        ("");
                    }

                    var fb = "";
                    if (b.id && b.type) {
                        fb = b.type;
                    } else {
                        ("");
                    }
                    if (!isDesc[0]) {
                        if (fa > fb) {
                            return -1;
                        }
                        if (fa < fb) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                }
            });

            return items;
        },
    },
    props: ["showAside", "contactid"],
};
</script>

<style lang="scss">
.select-filter {
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    width: 175px;
    height: 40px;
    background: white;
}

.select-filter:hover {
    border: 1px solid #c4cdd5;
    cursor: pointer;
}

.select-filter.download-center-filters-active {
    border: 2px solid #00848e;
}

.document-filters-footer {
    border: 1px solid #dfe3e8;
    border-top: none;
}

.v-input--selection-controls {
    margin-top: 0px !important;
}

.doc-list {
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>