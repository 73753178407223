<!-- BCT: MOdified for HB-76 -->
<template>
    <div style="position: absolute; top:0; bottom: 64px; width: 100%; ">
        <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto">
            <div class="border-bottom">
                <p class="font-regular mt-3 px-5 hb-text-night-light">Select how you want to adjust the price of the
                    selected spaces. </p>
            </div>
            <v-container class="hb-table" v-show="!showConfirm">
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pl-6 pt-4" cols="4">
                        Amount
                    </v-col>
                    <v-col class="hb-table-value">
                        <v-text-field dense flat hide-details prepend-inner-icon="mdi-currency-usd"
                            v-model.lazy="form.value" background-color="white" single-line label="Enter Price Change"
                            v-validate="'required|decimal:2|min_value:0'" id="Amount" name="Amount" data-vv-name="Amount"
                            :error-messages="errors.collect(`Amount`)">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="hb-table" v-show="showConfirm">
                <v-row class="hb-table-row">
                    <v-col class="hb-table-label pl-6 pt-4" cols="4">Space</v-col>
                    <v-col class="hb-table-value text text-right">Old Price</v-col>
                    <v-col class="hb-table-value text text-right">New Price</v-col>
                </v-row>
                <v-row class="hb-table-row" v-for="unit in this.adjustedUnits" :key="unit.id">
                    <v-col class="hb-table-label pl-6 pt-4" cols="4">#{{ unit.number }}</v-col>
                    <v-col class="hb-table-value text text-right">{{ getPreviousSellRate(unit.id) | formatMoney }}
                    </v-col>
                    <v-col class="hb-table-value text text-right"><span class="primary-color enhanced">{{ form.value |
                            formatMoney
                    }}</span></v-col>
                </v-row>
            </v-container>
        </v-card>

        <hb-bottom-action-bar @close="$emit('cancel')" v-show="!showConfirm" class="bottom-bar-position">
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="setEditForm">Next</hb-btn>
            </template>
        </hb-bottom-action-bar>

        <hb-bottom-action-bar :footerCancelOption="false" v-show="showConfirm" class="bottom-bar-position">
            <template v-slot:right-actions>
                <hb-btn color="secondary" @click="showConfirm = false">Back</hb-btn>
                <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save">Change Space Rate</hb-btn>
            </template>
        </hb-bottom-action-bar>

    </div>

</template>
  
<script>
import api from '../../../assets/api.js';
import Status from '../Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import Rounding from "../../assets/Rounding.vue";
import { EventBus } from '../../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from '@/mixins/notificationMixin.js';

export default {
    name: "BulkEditUnitSetRate",
    data() {
        return {
            showConfirm: false,
            price_value: '',
            types: ['Percent', 'Dollars'],
            form: {
                type: 'percent',
                value: ''
            },
            adjustedUnits: [],
            adjustedUnitsPrices: [],
            roundingData: null

        }
    },
    props: ['items', 'template'],
    mixins: [notificationMixin],

    created() {
        this.getUnitDetails();
        this.fetchCategories();
        this.show_dialog = true;
        EventBus.$on("saveRoundingData", this.saveRounding);
    },
    destroyed() {
        EventBus.$off("saveRoundingData", this.saveRounding);
    },
    components: {
        Status,
        Loader,
        HbDatePicker,
        Rounding
    },
    computed: {
        ...mapGetters({
            bulk_edit_items: 'bulkEditStore/items',
            getBulkUnitIds: 'reportStore/getBulkUnitsList',
        }),
    },
    methods: {
        ...mapActions({
            resetAction: 'bulkEditStore/resetAction',
            addBulkUnits: 'reportStore/addBulkUnits',
        }),
        getPreviousSellRate(unitId) {
            let value = this.units.find((unit) => unit.id === unitId)
            return value?.set_rate
        },
        saveRounding(rounding) {
            this.roundingData = rounding;
        },
        adjustedPrice() {
            return this.form.value;
        },
        closeWindow() {
            this.resetAction();
        },
        async getUnitDetails() {
            let unit_ids = this.getBulkUnitIds.map(unit=> { return {id:unit.unit_id} }) || []
            let items = this.items.map(item => { return {id:item.unit_id} });
            let response = await api.post(this, api.UNITS + 'bulk-config', {unit_ids: unit_ids.length? unit_ids:items});
            this.units = response.units || [];
        },
        async setEditForm() {

            this.validate(this).then(async status => {
                if (!status) {
                    let msg = 'Amount is missing a rate change.';
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: [msg] });
                    return
                }
                let error = false;
                await this.setAdjustedUnits(true);
                await api.put(this, api.UNITS + 'bulk-edit-prices', this.adjustedUnits).then(r => {
                    this.adjustedUnitsPrices = r;
                }).catch(err => {
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: [err] });
                    error = true;
                })
                if (error) return;

                await this.setAdjustedUnits();
                this.showConfirm = true;
            });
        },
        setAdjustedUnits(dryRun = false) {
            this.adjustedUnits = this.units.map(unit => {
                return {
                    id: unit.id,
                    number: unit.number,
                    price: unit.price,
                    set_rate: dryRun ? this.adjustedPrice() : this.adjustedUnitsPrices.find(u => u.unit_id == unit.id)?.set_rate,
                    adjusted: unit.price,
                    rounding: this.roundingData,
                    dryrun: dryRun
                }
            });
        },
        async save() {
            if (this.isLoading(this.$options.name)) return;
            let response = await api.put(this, api.UNITS + 'bulk-edit-prices', this.adjustedUnits);
            if (!response) return;
            this.showMessageNotification({ type: 'success', description: this.getMessage() });
            EventBus.$emit('unit_edited');
            this.addBulkUnits([]);
            this.$emit('cancel');
        },

        getMessage() {
            let units_data = this.getBulkUnitIds.length ? this.getBulkUnitIds : this.items
            let spaces = units_data.map(a => a.number);
            return 'The set rate has been changed to $' + this.form.value + ' for spaces ' + spaces.join(', ') + '.';
        }
    }
}

</script>
<style lang="scss" scoped>
.strike {
    text-decoration: line-through;
    color: #a0a0a0;
}

.enhanced {
    font-weight: 500;
}

.primary-color {
    color: #00727A;

}

.bottom-bar-position {
    ::v-deep .hb-bab-wrapper {
        height: 63px !important;
    }
}

.hb-table {
    border-right: 0px !important;
}
</style>
  